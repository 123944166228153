/* Make clicks pass-through */
#nprogress {
	pointer-events: none;
	background: #144a7f;

	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;

	width: 100%;
	height: 8px;
}

#nprogress .bar {
	position: absolute;
	top: 0;
	right: 100%;
	bottom: 0;
	left: 0;
	background: linear-gradient(66deg, #144a7f, #051e34, #191919);
	width: 0;
	animation: borealisBar 4s linear infinite;
}

@keyframes borealisBar {
	0% {
		background-position: 0% 51%;
		width: 0%;
		left: 0%;
		right: 100%;
	}
	10% {
		left: 0%;
		right: 90%;
		width: 10%;
	}
	50% {
		background-position: 100% 50%;
		width: 100%;
	}
	90% {
		right: 0%;
		left: 90%;
		width: 10%;
	}
	100% {
		background-position: 0% 51%;
		width: 0%;
		left: 100%;
		right: 0%;
	}
}
